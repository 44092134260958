import { IRoutingConfig, Route, CandidateRouteInfo } from './types'
import { queryParamsWhitelist } from './queryParamsWhitelist'
import { resolveQueryParams, removeProtocol, replaceProtocol, convertHashBangUrlToSlashUrl } from './urlUtils'

const getRelativePathname = (url: string, baseUrl: string): string => {
	const parsedUrl = new URL(url, `${baseUrl}/`)
	const parsedBaseUrl = new URL(baseUrl)

	return parsedUrl.pathname.replace(parsedBaseUrl.pathname, '')
}

const removeLeadingAndTrailingSlash = (str: string): string => /^\/?(.*?)\/?$/.exec(str)![1]

const getPathnameDecodedParts = (relativePathname: string) => {
	const cleanPath = removeLeadingAndTrailingSlash(relativePathname)

	try {
		return decodeURIComponent(cleanPath).split('/')
	} catch (e) {
		return cleanPath.split('/')
	}
}

const getPathnameParts = (relativePathname: string) => removeLeadingAndTrailingSlash(relativePathname).split('/')

const pathnamePartsToRelativeUrl = (pathnameParts: Array<string>): string => `./${pathnameParts.join('/')}`

const isInternalUrl = (url: string, baseUrl: string): boolean => {
	const parsedUrl = new URL(url, `${baseUrl}/`)
	const parsedBaseUrl = new URL(baseUrl)
	return parsedUrl.host === parsedBaseUrl.host && parsedUrl.pathname.startsWith(parsedBaseUrl.pathname)
}

const getRelativeUrlData = (
	url: string,
	baseUrl: string
): {
	relativePathnameParts: Array<string>
	relativeUrl: string
	relativeEncodedUrl: string
} => {
	const relativePathname = getRelativePathname(url, baseUrl)
	const relativePathnameParts = getPathnameDecodedParts(relativePathname)
	const relativeUrl = pathnamePartsToRelativeUrl(relativePathnameParts)
	const relativeEncodedUrl = pathnamePartsToRelativeUrl(getPathnameParts(relativePathname))

	return {
		relativePathnameParts,
		relativeUrl,
		relativeEncodedUrl,
	}
}

export const getRelativeUrl = (url: string, baseUrl: string) => getRelativeUrlData(url, baseUrl).relativeUrl

export const getRelativeEncodedUrl = (url: string, baseUrl: string) =>
	getRelativeUrlData(url, baseUrl).relativeEncodedUrl

const getRouteData = (relativePathnameParts: Array<string>, routes: IRoutingConfig['routes']): Route | undefined => {
	const routeKey = `./${relativePathnameParts[0]}`

	return routes[decodeURIComponent(routeKey)]
}

export const keepInternalQueryParamsOnly = (searchParams: URLSearchParams) => {
	// @ts-ignore - ts thinks there's no entries() on searchParams, but it's there.
	const entries = searchParams.entries()
	const filteredEntries = [...entries].filter(([key]) => queryParamsWhitelist.has(key))
	return new URLSearchParams(filteredEntries).toString()
}

const isUrlOnSameWixSite = (candidateUrl: string, baseUrl: string) => {
	const candidateUrlWithNoProtocol = removeProtocol(candidateUrl)
	const baseUrlWithNoProtocol = removeProtocol(baseUrl)

	return candidateUrlWithNoProtocol.startsWith(baseUrlWithNoProtocol)
}

const fixSameWixSiteCandidateUrl = (candidateUrl: string, siteProtocol: string) => {
	const candidateSlashUrl = convertHashBangUrlToSlashUrl(candidateUrl)
	return replaceProtocol(candidateSlashUrl, siteProtocol)
}

export const resolveUrl = (
	url: string,
	routingConfig: IRoutingConfig,
	currentParsedUrl?: URL
): Partial<CandidateRouteInfo> => {
	const isHomePageUrl = url === './'
	const queryParams = currentParsedUrl ? keepInternalQueryParamsOnly(currentParsedUrl.searchParams) : ''

	// should resolve to base url on home page url, otherwise we get extra slash on navigation
	const candidateUrl = isHomePageUrl ? routingConfig.baseUrl : url
	const urlToParse = isUrlOnSameWixSite(candidateUrl, routingConfig.baseUrl)
		? fixSameWixSiteCandidateUrl(candidateUrl, new URL(routingConfig.baseUrl).protocol)
		: candidateUrl

	const parsedUrl = new URL(urlToParse, `${routingConfig.baseUrl}/`)
	parsedUrl.search = resolveQueryParams(parsedUrl.search, queryParams)

	if (!isInternalUrl(urlToParse, routingConfig.baseUrl)) {
		return {
			parsedUrl,
		}
	}

	const { relativeUrl, relativeEncodedUrl, relativePathnameParts } = getRelativeUrlData(
		urlToParse,
		routingConfig.baseUrl
	)
	const route = getRouteData(relativePathnameParts, routingConfig.routes)

	return {
		...route,
		relativeUrl,
		relativeEncodedUrl,
		parsedUrl,
	}
}
