import _ from 'lodash'
import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	Fetch,
	IFetchApi,
	SiteFeatureConfigSymbol,
	ILogger,
	LoggerSymbol,
	SdkHandlersProvider,
	BrowserWindow,
	BrowserWindowSymbol,
	DynamicSessionModel,
} from '@wix/thunderbolt-symbols'
import {
	SessionManagerSiteConfig,
	ISessionManager,
	SessionHandlers,
	LoadNewSessionReason,
	OnLoadSessionCallback,
} from './types'
import { name } from './symbols'

const DEFAULT_EXPIRATION_TIME = 210 * 60 * 1000 // 210 minutes = 3.5 hours

const sessionManagerFactory = (
	browserWindow: BrowserWindow,
	siteFeatureConfig: SessionManagerSiteConfig,
	fetchApi: IFetchApi,
	logger: ILogger
): ISessionManager & SdkHandlersProvider<SessionHandlers> => {
	let sessionTimeoutPointer: number

	const _onLoadSessionCallbacks: Array<OnLoadSessionCallback> = []

	const addLoadNewSessionCallback = (callback: OnLoadSessionCallback) => {
		_onLoadSessionCallbacks.push(callback)
	}

	const _sessionModel: Partial<DynamicSessionModel> = {}

	browserWindow!.fetchDynamicModel.then((model) => {
		if (typeof model !== 'object') {
			logger.captureError(new Error(`failed fetching dynamicModel`), {
				tags: { feature: 'session-manager', fetchFail: 'dynamicModel' },
				extra: { errorMessage: model, attempt: 2 },
			})
			return
		}
		Object.assign(_sessionModel, model)
	})

	const getAppInstanceByAppDefId = (
		appDefId: string,
		sessionModel: Partial<DynamicSessionModel>
	): string | undefined => {
		const { instance } = (sessionModel.apps && sessionModel.apps[appDefId]) || {}
		return instance
	}

	const invokeSessionLoadCallbacks = (reason: LoadNewSessionReason) => {
		const instances = _.mapValues(_sessionModel.apps, 'instance')
		_onLoadSessionCallbacks.forEach((callback) =>
			callback({
				results: { instances, siteMemberId: _sessionModel.siteMemberId, visitorId: _sessionModel.visitorId },
				reason,
			})
		)
	}

	const loadNewSession: ISessionManager['loadNewSession'] = async ({ reason } = { reason: 'noSpecificReason' }) => {
		try {
			const newSession = await fetchApi
				.envFetch(siteFeatureConfig.dynamicModelApiUrl, { credentials: 'same-origin' })
				.then((res) => res.json())
			Object.assign(_sessionModel, newSession)
			invokeSessionLoadCallbacks(reason)
		} catch (error) {
			logger.captureError(new Error(`failed fetching dynamicModel`), {
				tags: { feature: 'session-manager', fetchFail: 'dynamicModel' },
				extra: { errorMessage: error.message },
			})
		}

		setSessionTimeout()
	}

	const setSessionTimeout = () => {
		if (sessionTimeoutPointer) {
			browserWindow!.clearTimeout(sessionTimeoutPointer)
		}

		sessionTimeoutPointer = browserWindow!.setTimeout(
			() => loadNewSession({ reason: 'expiry' }),
			siteFeatureConfig.expiryTimeoutOverride || DEFAULT_EXPIRATION_TIME
		)
	}

	// set initial timeout for refresh
	setSessionTimeout()

	return {
		getAllInstances() {
			return _sessionModel.apps || {}
		},
		getAppInstanceByAppDefId(appDefId: string) {
			return getAppInstanceByAppDefId(appDefId, _sessionModel)
		},
		getSiteMemberId() {
			return _sessionModel.siteMemberId
		},
		getVisitorId() {
			return _sessionModel.visitorId
		},
		loadNewSession,
		addLoadNewSessionCallback,
		getHubSecurityToken() {
			return String(_sessionModel.hs || 'NO_HS')
		},
		getUserSession() {
			return _sessionModel.svSession
		},
		getCtToken() {
			return _sessionModel.ctToken
		},
		setUserSession(svSession: string) {
			_sessionModel.svSession = svSession
		},
		getSdkHandlers: () => ({
			onLoadSession: (callback: OnLoadSessionCallback) => {
				addLoadNewSessionCallback(callback)
			},
			getMediaAuthToken: () => Promise.resolve(_sessionModel.mediaAuthToken),
			loadNewSession,
		}),
	}
}

export const SessionManager = withDependencies(
	[BrowserWindowSymbol, named(SiteFeatureConfigSymbol, name), Fetch, LoggerSymbol],
	sessionManagerFactory
)
