import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { SessionManagerSymbol, name } from './symbols'
import { SessionManager as ClientSessionManager } from './clientSessionManager'
import { SessionManager as ServerSessionManager } from './serverSessionManager'
import { WixCodeSdkHandlersProviderSym } from '@wix/thunderbolt-symbols'

export const site: ContainerModuleLoader = (bind) => {
	if (process.env.browser) {
		bind(SessionManagerSymbol, WixCodeSdkHandlersProviderSym).to(ClientSessionManager)
	} else {
		bind(SessionManagerSymbol).to(ServerSessionManager)
	}
}

export { ISessionManager, SessionHandlers, Instance } from './types'

export { SessionManagerSymbol, name }
