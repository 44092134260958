import React, { Fragment, ComponentType, ReactNode, useEffect, useLayoutEffect } from 'react'

export type PageMountUnmountProps = {
	children: () => ReactNode
	pageDidMount: (isMounted: boolean) => void
	codeEmbedsCallback?: Function
}

const PageMountUnmount: ComponentType<PageMountUnmountProps> = ({
	children,
	pageDidMount = () => {},
	codeEmbedsCallback = undefined,
}) => {
	useEffect(() => {
		pageDidMount(true)
		return () => {
			pageDidMount(false)
		}
	}, [pageDidMount])
	useLayoutEffect(() => {
		codeEmbedsCallback && codeEmbedsCallback()
	})
	return <Fragment>{children()}</Fragment>
}

export default PageMountUnmount
