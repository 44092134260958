import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { FastNavigationManager } from './fastNavigationManager'
import { FastNavigationPageDidMountHandler } from './fastNavigationPageDidMountHanlder'
import { LifeCycle } from '@wix/thunderbolt-symbols'
import { FastNavigationManagerSymbol } from './symbols'

export const site: ContainerModuleLoader = (bind) => {
	bind(FastNavigationManagerSymbol).to(FastNavigationManager)
}

export const editor = site

export const page: ContainerModuleLoader = (bind) => {
	bind(LifeCycle.PageDidMountHandler, LifeCycle.PageDidUnmountHandler).to(FastNavigationPageDidMountHandler)
}

export { IFastNavigationManager } from './types'
export { FastNavigationManagerSymbol }
