import { withDependencies } from '@wix/thunderbolt-ioc'
import {
	DROPDOWN_MENU_COMPONENT_TYPE,
	EXPANDABLE_MENU_COMPONENT_TYPE,
	VERTICAL_MENUS_COMPONENTS_TYPES,
	STYLABLE_HORIZONTAL_MENU_TYPE,
} from './relevantComponentTypes'
import { IUrlHistoryManager, UrlHistoryManagerSymbol } from 'feature-router'
import { IComponentPropsExtender } from 'feature-components'

const VerticalMenusFactory = (
	urlHistoryManager: IUrlHistoryManager
): IComponentPropsExtender<{ currentPageHref: string }, unknown> => {
	return {
		componentTypes: [...VERTICAL_MENUS_COMPONENTS_TYPES, STYLABLE_HORIZONTAL_MENU_TYPE],
		getExtendedProps: () => ({ currentPageHref: urlHistoryManager.getRelativeUrl() }),
	}
}

export const VerticalMenus = withDependencies([UrlHistoryManagerSymbol], VerticalMenusFactory)

const ExpandableAndDropdownMenusFactory = (
	urlHistoryManager: IUrlHistoryManager
): IComponentPropsExtender<{ currentPrimaryPageHref: string }, unknown> => {
	return {
		componentTypes: [EXPANDABLE_MENU_COMPONENT_TYPE, DROPDOWN_MENU_COMPONENT_TYPE],
		getExtendedProps: () => ({ currentPrimaryPageHref: urlHistoryManager.getRelativeUrl() }),
	}
}

export const ExpandableAndDropdownMenus = withDependencies([UrlHistoryManagerSymbol], ExpandableAndDropdownMenusFactory)
