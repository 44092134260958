import { withDependencies } from '@wix/thunderbolt-ioc'
import { IPageDidMountHandler, IPageDidUnmountHandler } from '@wix/thunderbolt-symbols'
import { IFastNavigationManager } from './types'
import { FastNavigationManagerSymbol } from './symbols'

const fastNavigationPageDidMountHandler = (
	fastNavigationManager: IFastNavigationManager
): IPageDidMountHandler & IPageDidUnmountHandler => {
	return {
		pageDidMount: () => {
			if (fastNavigationManager.isFirstNavigation()) {
				fastNavigationManager.endNavigation()
			}
		},
		pageDidUnmount: () => {
			if (!fastNavigationManager.isFirstNavigation()) {
				fastNavigationManager.endNavigation()
			}
		},
	}
}

export const FastNavigationPageDidMountHandler = withDependencies(
	[FastNavigationManagerSymbol],
	fastNavigationPageDidMountHandler
)
