import { withDependencies } from '@wix/thunderbolt-ioc'
import { IFastNavigationManager } from './types'
import { createPromise } from '@wix/thunderbolt-commons'

const fastNavigationManager = (): IFastNavigationManager => {
	let isDuringNavigation = false
	let isFirstNavigation = true
	let navAwaiterResolvers: Array<(isLast: boolean) => void> = []
	return {
		endNavigation: () => {
			isDuringNavigation = false
			isFirstNavigation = false
			navAwaiterResolvers.forEach((resolver, idx) => resolver(idx === navAwaiterResolvers.length - 1))
			navAwaiterResolvers = []
		},
		isDuringNavigation: () => isDuringNavigation,
		isFirstNavigation: () => isFirstNavigation,
		startNavigation: () => {
			isDuringNavigation = true
		},
		waitForShouldContinueNavigation: () => {
			const { resolver, promise } = createPromise<boolean>()
			navAwaiterResolvers.push(resolver)
			return promise
		},
	}
}

export const FastNavigationManager = withDependencies([], fastNavigationManager)
